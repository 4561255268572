/**
 * Check if the path starts with '//' and append 'https'.
 * Mainly used for links being returned from Contentful.
 * Also Replaces all images from downloads.ctfassets.net to images.ctfassets.net to use the image API
 * @param imageURL
 * @returns string
 */
const postFixUrl = (imageURL: string): string => {
	return imageURL.replace(/^\/\//, 'https://').replace(/downloads\.ctfassets\.net/g, 'images.ctfassets.net');
};

export default postFixUrl;
