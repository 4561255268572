import { checkCache, storeInCache } from '@vakantiesnl/components/src/utils/redis';
import { ContentTemplate, RawContentTemplate, RawSearchTemplate, SearchTemplate } from '@vakantiesnl/types/src';

import { mapBrandedSearchTemplate, mapContentTemplate } from '../contentful/mapper';
import { resolveBySlug } from '../contentful/slug-resolver';
import { filterContentfulSlugFromPath } from '../util/urlUtils';

export async function getCachedSearchTemplateFromContentful(
	contentfulSlug: string,
	locale = 'nl-NL',
): Promise<SearchTemplate | undefined> {
	let data = await checkCache<SearchTemplate>(`contentful:${contentfulSlug}:${locale}`);

	if (!data) {
		const rawData = await resolveBySlug<RawSearchTemplate>(contentfulSlug, 'templateBrandedSearchPage');
		if (rawData) {
			data = mapBrandedSearchTemplate(rawData);
			await storeInCache(`contentful:${contentfulSlug}:${locale}`, data);
		}
	}

	return data;
}

export const getCachedContentTemplateFromContentful = async (
	asPath: string,
	dynamicContent?: string,
): Promise<ContentTemplate> => {
	const contentfulSlug = filterContentfulSlugFromPath(asPath);
	const rawData = await resolveBySlug<RawContentTemplate>(contentfulSlug, undefined);
	return mapContentTemplate(rawData, dynamicContent);
};
