import { EntryCollection } from 'contentful';

import { getEntryCollection } from './index';

export const resolveBySlug = async <Template extends Record<string, unknown>>(
	slug: string,
	contentType?: string,
	showNoError?: boolean,
): Promise<EntryCollection<Template>> => {
	try {
		const entries = await getEntryCollection<Template>(contentType || 'contentpage', {
			include: 3,
			limit: 1,
			'fields.seo.sys.contentType.sys.id': 'seo',
			'fields.seo.fields.slug': slug,
		});

		if (!showNoError && entries.items.length === 0) {
			throw new Error(`Page not found ${slug}`);
		}

		return entries;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error('Error resolving slug from Contentful: ', err);
		throw err;
	}
};
