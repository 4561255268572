import { FilterState } from '../stores/filtersStore';

export const countriesToSetOwnTransportFor = [
	'0b661d17-c2be-4478-be80-2bd2273d4445', // Netherlands
	'be80ee67-8b85-4de0-8af2-3fe71c0e5ed2', // France
	'5a94a463-b674-4f5f-a397-f6cef6b8099a', // Italy
	'55ddb7d6-45f0-4ddc-8d11-516205197ca4', // Croatia
	'1efef861-caaf-4443-9d35-c765757ff585', // Belgium
	'1a01feb5-4a85-41e2-a0bf-aabf7e2a07ec', // Luxembourg
	'0c0f092b-de93-4dc5-9b06-c0af0e676bc6', // Germany
	'3ad9a2df-4b28-415e-a98d-022423561b82', // Austria
	'236b8f6f-1fd1-44e2-a89d-3614d08021c2', // Switzerland
	'9f216293-8597-43e8-86ba-7b666465301f', // Denmark
	'8f8acaa4-78b8-48f8-8c38-494d752fe4bd', // Sweden
	'a1184e1e-6233-4516-b6b7-95a7aec2f406', // Norway
	'44815157-cfa9-4db0-92c4-ff06bd406082', // Andorra
];

export const accoTypesToSetOwnTransportFor = [
	'camping', // Campings
	'resort', // Vakantieparken
	'bed_and_breakfast', // Bed & Breakfast
	'lodge', // Lodges
	'pension', // Pensions
];

export const campaignsToSetOwnTransportFor = ['wintersport', 'autovakantie'];

export const campaignsToHideTransport = ['all-inclusive', 'autovakantie'];

export function getAllowTransportFilters(filters: FilterState): boolean {
	if (filters.campaign) {
		return !campaignsToHideTransport.includes(filters.campaign);
	}

	return true;
}
