import { identity, pickBy } from 'lodash';

import { ImageComponentProps } from '../../types/index';

const CloudImageLoader = ({
	quality,
	rotationDegrees,
	src,
	trim,
	width,
}: Pick<ImageComponentProps, 'quality' | 'rotationDegrees' | 'src' | 'trim' | 'width'>): string => {
	let height: number | undefined;

	if (trim) {
		const trimWidth = trim[0] || 0;
		const trimHeight = trim[1] || undefined;

		let trimmedWidth = trimWidth;
		let trimmedHeight = trimHeight;

		if (typeof trimWidth === 'number' && trimWidth > 0) {
			/** Trim based on aspect ratio to support different screen sizes */
			width = width ? (typeof width === 'string' ? parseInt(width, 10) : width) : undefined;
			if (width && width / trimWidth >= 1) {
				const trimWidthRemainder = width % trimWidth;
				trimmedWidth = Math.round(width - trimWidthRemainder);

				if (typeof trimHeight === 'number') {
					const trimAspectRatio = trimWidth / trimHeight;
					trimmedHeight = Math.round(trimmedWidth / trimAspectRatio);
				}
			}
		}

		height = trimmedHeight;
		width = trimmedWidth;
	}

	// Get the URL params from images so we can append them later. We use this for images coming from Booking for example
	let urlObj;
	let url;
	try {
		urlObj = new URL(src as string);
	} catch (_) {
		url = src as string;
	}

	let urlParams;
	if (urlObj) {
		urlParams = urlObj.searchParams;
	}

	const queryParams = pickBy(
		{
			...Object.fromEntries(urlParams ? urlParams : []),
			h: height ? height.toString() : undefined,
			optipress: !quality ? 2 : undefined,
			q: quality ? quality.toString() : undefined,
			r: rotationDegrees ? rotationDegrees : undefined,
			w: width ? width.toString() : undefined,
		},
		identity,
	) as Record<string, string>;

	// Remove the default query params since we already copied them to the new queryParams object above
	if (urlObj) {
		urlObj.search = '';
	}

	const urlString = urlObj ? urlObj.toString() : url;

	return urlString?.includes('?')
		? `https://${process.env.NEXT_PUBLIC_CLOUDIMG_IO_TOKEN}.cloudimg.io/v7/${urlString}&${new URLSearchParams(
				queryParams,
		  ).toString()}`
		: `https://${process.env.NEXT_PUBLIC_CLOUDIMG_IO_TOKEN}.cloudimg.io/v7/${urlString}?${new URLSearchParams(
				queryParams,
		  ).toString()}`;
};

export default CloudImageLoader;
