const resolveResponse = require('./resolve-response');

const getBaseParameters = () => {
	const baseParameters = new URLSearchParams();
	baseParameters.append('include', '10');

	baseParameters.append('access_token', process.env.NEXT_PUBLIC_CONTENTFUL_API_KEY);
	return baseParameters;
};

const getZvrBaseParameters = () => {
	const baseParameters = new URLSearchParams();
	baseParameters.append('include', '10');

	baseParameters.append('access_token', process.env.NEXT_PUBLIC_ZVR_CONTENTFUL_API_KEY);
	return baseParameters;
};

const getCDNUrl = () => process.env.NEXT_PUBLIC_CONTENTFUL_URL;

const getEntryCollection = async (contentType, query = {}, locale = 'nl-NL') => {
	const baseParameters = getBaseParameters();
	baseParameters.set('content_type', contentType);
	Object.keys(query).forEach((key) => {
		baseParameters.set(key, query[key]);
	});

	const url = `${getCDNUrl()}/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE}/environments/${
		process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_ID
	}/entries?${baseParameters}&locale=${locale}`;

	const response = await fetch(url);
	const data = await response.json();
	data.items = resolveResponse(data, { itemEntryPoints: ['fields'] });

	return data;
};
const getZvrEntryCollection = async (contentType, query = {}, locale = 'nl-NL') => {
	const baseParameters = getZvrBaseParameters();
	baseParameters.set('content_type', contentType);
	Object.keys(query).forEach((key) => {
		baseParameters.set(key, query[key]);
	});

	const url = `${getCDNUrl()}/spaces/${process.env.NEXT_PUBLIC_ZVR_CONTENTFUL_SPACE}/environments/${
		process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_ID
	}/entries?${baseParameters}&locale=${locale}`;

	const response = await fetch(url);
	const data = await response.json();
	data.items = resolveResponse(data, { itemEntryPoints: ['fields'] });

	return data;
};

const getEntry = async (id, contentType) => {
	const baseParameters = getBaseParameters();
	if (contentType) {
		baseParameters.set('content_type', contentType);
	}

	const url = `${getCDNUrl()}/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE}/environments/${
		process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_ID
	}/entries/${id}?${baseParameters}`;

	const response = await fetch(url);
	const data = await response.json();
	data.items = resolveResponse(data, { itemEntryPoints: ['fields'] });

	return data;
};

const getAsset = async (id) => {
	const baseParameters = getBaseParameters();
	const url = `${getCDNUrl()}/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE}/environments/${
		process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_ID
	}/assets/${id}?${baseParameters}`;

	const response = await fetch(url);
	return await response.json();
};

const fetchMicroCopies = async (locale) => {
	const { total } = await getEntryCollection('microcopy', { limit: 0 }, locale);
	let rawEntriesItem = [];
	/** 1000 is the max amount of microcopies the Contentful CDN can handle so we loop over the total amount */
	const numberOfLoops = Math.ceil(total / 1000);
	for (let i = 0; i < numberOfLoops; i++) {
		const rawEntry = await getEntryCollection('microcopy', { limit: 1000, skip: i * 1000 }, locale);

		rawEntriesItem.push(...rawEntry.items);
	}

	return { items: rawEntriesItem };
};

const fetchLocales = async () => {
	const url = `${getCDNUrl()}/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE}/environments/${
		process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT_ID
	}/locales?access_token=${process.env.NEXT_PUBLIC_CONTENTFUL_API_KEY}`;
	const response = await fetch(url);
	const data = await response.json();

	let locales = [];
	if (data && data.items && data.items.length > 0) {
		locales = data.items.map((item) => item.code);
	} else {
		locales = ['nl-NL'];
	}

	return { locales };
};

module.exports.getCDNUrl = getCDNUrl;
module.exports.getEntryCollection = getEntryCollection;
module.exports.getZvrEntryCollection = getZvrEntryCollection;
module.exports.getEntry = getEntry;
module.exports.getAsset = getAsset;
module.exports.fetchMicroCopies = fetchMicroCopies;
module.exports.fetchLocales = fetchLocales;
