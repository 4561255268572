export const parseAspectRatio = (ar: string): number | null => {
	if (!ar.includes(':')) {
		// eslint-disable-next-line no-console
		console.info('Syntax not recognized for aspect ratio argument (ex: 4:3, 16:9)');
		return null;
	}

	const [w, h] = ar.split(':');
	return Number(h) / Number(w);
};
