import Head from 'next/head';
import Image, { ImageLoaderProps } from 'next/image';

import CloudImageLoader from './loaders/cloudimage';
import { ContentfulImageParams, ContentfulLoader } from './loaders/contentful';
import postFixUrl from './utils/postFixUrl';
import { ImageComponentProps } from '../types/index';

type Props = Omit<ImageComponentProps, 'useLoader'> &
	(
		| { useLoader?: 'CloudImage' | false; contentfulParams?: never }
		| { useLoader: 'Contentful'; contentfulParams?: ContentfulImageParams }
	);

const ImageComponent = ({
	useLoader = 'CloudImage',
	rotationDegrees,
	trim,
	src,
	contentfulParams,
	...rest // Using rest here since all attributes of an img element can be passed through
}: Props): JSX.Element => {
	let preconnectDomain;
	switch (useLoader) {
		case false:
			rest.loader = undefined;
			break;
		case 'Contentful':
			rest.loader = (props: ImageLoaderProps): string => ContentfulLoader(props, contentfulParams);

			preconnectDomain = process.env.NEXT_PUBLIC_CONTENTFUL_IMAGES_URL;
			break;
		default:
			rest.loader = ({ quality, src, width }: ImageLoaderProps): string =>
				CloudImageLoader({ quality, rotationDegrees, src, trim, width });
			preconnectDomain = `https://${process.env.NEXT_PUBLIC_CLOUDIMG_IO_TOKEN}.cloudimg.io`;
			break;
	}

	return (
		<>
			{preconnectDomain && useLoader && (
				<Head>
					<link rel="preconnect" key={`${useLoader}-preconnect`} href={preconnectDomain} />
					<link rel="dns-prefetch" key={`${useLoader}-dns-prefetch`} href={preconnectDomain} />
				</Head>
			)}
			<Image loader={rest.loader} src={typeof src === 'string' ? postFixUrl(src) : src} {...rest} />
		</>
	);
};

export default ImageComponent;
